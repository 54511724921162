/**
 * About Sizing
 */
 .about-holder h1,
 .about-holder h2,
 .about-holder h3 {
	font-family:"lemonchickenregular";
	font-weight: normal;
	font-size: 60px;
	color:#173e8a;
	margin: 0;
	margin-right: 10px;
}
.about-holder h2 {
	font-size: 40px;
	color:#BC412B;
}

.about-holder h3 {
	font-size: 30px;
	color:#BC412B;
}
