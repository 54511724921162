/**
 * Park Styles
 */

 .trip-info {
	display: grid;
	grid-template-columns: 3fr 1fr;
	grid-template-rows: 1 1fr;
	grid-column-gap: 40px;
	grid-row-gap: 5px;
 }
 .trip-info .visit-details {
 	width:100%;
 }
 .trip-info .activity-list {
 	width:20%;
 	margin-left: 20px;
 }
.trip-info .visit-details .react-tabs__tab {
	transition:0.3s ease;
}
.trip-info .visit-details .react-tabs__tab:hover {
	border-top: 2px solid;
}
.trip-info .visit-details .react-tabs__tab.react-tabs__tab--selected {
	background: #fff;
	border-color: #1865a7 !important;
	border-radius: 5px 5px 0 0;
	border-top: 5px solid;
	border-radius: 0;
	border-left-color: transparent !important;
	border-right-color: transparent !important;
}