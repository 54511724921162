/**
 * Make border-box the default CSS box-model
 */
html {
	font-size: 10px;
	font-family: 'Raleway', sans-serif;
	height: 100%;
}
*,
*::before,
*::after {
	box-sizing: border-box;
}

@font-face {
	font-family: 'lemonchickenregular';
	src: url('https://static.alphavega.com/fonts/LEMONCHI-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}


.nps-logo {
	width: 45px;
}

.App { font-size: 20px; }
.App .main-container {
	margin:20px;
	min-height: 100vh;
	max-width: 100vw;
	margin-top:40px;
}

.flex { display: flex; }
.hidden { display: none; }

@media screen and (max-width: 700px) {
	.App .main-container {
		margin:15px;
		margin-top: 40px;
	}
}