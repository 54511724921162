/**
 * Activity List
 */
.all-activity-list .day-activity-list {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-template-rows: repeat(5, 1fr);
	grid-column-gap: 5px;
	grid-row-gap: 5px;
}
@media screen and (min-width:1600px) and (max-width:1730px) {
	.all-activity-list .day-activity-list {
		grid-template-columns: repeat(5, 1fr);
		grid-template-rows: repeat(4, 1fr);
	}
}
@media screen and (min-width:1731px){
	.all-activity-list .day-activity-list {
		grid-template-columns: repeat(6, 1fr);
		grid-template-rows: repeat(4, 1fr);
	}
}


.all-activity-list .day-activity-list .activity-2 .icon{
	height: 100%;
	width: auto;
	margin: 0;
}

.all-activity-list .day-activity-list .activity-2:hover .icon {
	transform: scale(0.75);
}

.all-activity-list .activity-name {
	width: 100%;
	background: #1f80f9;
	border-radius: 5px 5px 0 0;
	-webkit-transition: 0.2s ease-in;
	transition: 0.2s ease-in;
	text-align: center;
	position: absolute;
	top: 50%;
	left: 0;
	margin-top: -20px;
	border-radius: 5px;
	font-size: 18px;
	transform: scale(0);
}

.all-activity-list .day-activity-list .activity-2:hover .activity-name {
	transform: scale(1);
}

.all-activity-list .activity-2:not(.done-today) .icon {
	filter: blur(1px);
	opacity: 0.7;
}