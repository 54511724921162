/**
 * New Zealand Styles
 */
.new-zealand .park-header .park-name {
	width:500px;
}
.new-zealand .park-header .other-links {
	width:600px;
}
.new-zealand .park-header .other-links p { margin: 0; }
.new-zealand .visit-details { width: 100%; }

.new-zealand .react-tabs__tab {
	border-top:5px solid transparent;
}

.new-zealand .trip-info {
	display: grid;
	grid-template-columns: 3fr 1fr;
	grid-template-rows: 1 1fr;
	grid-column-gap: 40px;
	grid-row-gap: 5px;
}

@media only screen and (max-device-width: 667px) and (min-device-width: 375px) {
	.new-zealand .trip-info { display: flex; }
}
.new-zealand .review-day .day-activities { display: none; }
