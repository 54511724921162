/**
 * ParkInfo.css
 */
.photo-park-info {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 80px;
	box-shadow: 1px -34px 3px -34px #020a23;
	padding-top: 20px;
	width: 50%;
	text-align: center;
	align-self: center;
}
.photo-park-info .park-name {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.photo-park-info h3 {
	font-family: "lemonchickenregular";
	font-weight: normal;
	font-size: 2rem;
	/*margin:30px 0;*/
	text-align: center;
}

.photo-park-info .rating {
	font-weight: bold;
}