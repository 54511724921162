.photo-gallery {
	margin:40px;
}

.photo-gallery .react-photo-gallery--gallery > div { justify-content: center; }



@media screen and (max-width: 400px) {
	.photo-gallery {
		margin:10px;
	}
}