.time-lapse-focused {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;	
}

.time-lapse-focused .video-figure {
	display:flex;
	flex-direction:column;
	justify-content:center;
}

.video-figure .time-video {
	width:auto;
	height: 35vw;
}

.time-lapse-focused .next-photo-control {
	width:50px;
	height: 100%;
	margin-right: -20px;
	height: 85vh;
	transition: 0.4s ease;
	cursor: pointer;
}
.time-lapse-focused .next-photo-control.is-previous {
	margin-left: -20px;
	margin-right: 0;
}
