.img-caption .title {
	font-family: "lemonchickenregular";
	font-size: 20px;
}

.swish-title a { text-decoration: none; }
.swish-title .title,
.time-thumb .img-caption .title {
	margin: 10px 00;
	border-bottom: 2px solid;
	border-top: 2px solid;
	padding: 5px;
	position: relative;
}

.time-thumb-link:hover .img-caption .title {
	color:hsl(208, 99%, 50%);
}

.swish-title .title::before,
.swish-title .title::after,
.img-caption .title::before,
.img-caption .title::after {
	content:"";
	position: absolute;
	width:0;
	height: 2px;
	background-color:hsl(208, 99%, 50%);
	transition: 0.5s width ease-out;
}

.swish-title .title::before,
.img-caption .title::before {
	top: -2px;
	left: 0;
}
.swish-title .title::after,
.img-caption .title::after {
	bottom:-2px;
	right:0;
}

.swish-title:hover .title::before,
.swish-title:hover .title::after,
.time-thumb-link:hover .img-caption .title::before,
.time-thumb-link:hover .img-caption .title::after {
	width:100%;
	transition: 0.5s width ease-out;
}
