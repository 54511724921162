/**
 * Activity.css
 */

.activity-list h4 {
	text-align: center;
	font-family: "lemonchickenregular";
	font-size: 30px;
	color:#0f520f;
	margin:0;
	text-align: center;
	border-bottom:1px solid;
}

.activity-list .activity-park-name {
	font-weight: bold;
	color:#B098A4;
	border-bottom: 1px dotted;
}

.activity-groups {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}
/*.activity {
	margin: 0 10px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	overflow: hidden;
	width: 10rem;
}
.activity-icon,
.activity img {
	width: 100%;
	height: 8rem;
	margin: 0 auto;
	text-align: center;
	display: block;
	transform: translateY(100%);
	/*transform: translateY(2rem);*/
	/*transition: 0.3s 0.1s ease;*/
	/*padding-bottom: 0.5rem;*/
/*}
*/
/*.activity-name {
	width: 100%;
	background: #1f80f9;
	padding: 0.5rem 0;
	border-radius: 5px 5px 0 0;
	transform: translateY(3.5rem);
	transition: 0.2s  ease-in;
}*/

/*.activity img { display: none; }*/
/*.activity-icon {
	background-color: #035010;
	width:100%;
	height: 9rem;
}

.activity:hover img,
.activity:hover .activity-icon,
.activity:hover .activity-name {
	transform: translateY(-3rem);
}
*/
/*.activity[data-activity-name="Rock Climbing"] .activity-name,
.activity[data-activity-name="Scenic Drive"] .activity-name {
	transform: translateY(6.25rem);
}
.activity[data-activity-name="Rock Climbing"]:hover .activity-name,
.activity[data-activity-name="Scenic Drive"]:hover .activity-name {
	transform: translateY(6.25rem);
}*/

.activity-distance {
	background-color: #fff;
	position: relative;
	z-index: 1;
}
.activity-distance b {
	padding-left: 0.5rem;
}

@media screen and (max-width: 800px) {
	.activity {
		width:7rem;
	}
	.activity img {
		max-height:5rem;
	}
	.activity-distance {
		font-size:1.5rem;
	}
}


/**
 * New Activity styles:;
 */
.activity-2 {
	width: 75px;
	margin: 5px 10px;
	display: flex;
	justify-content: flex-end;
	flex-direction: column;
	overflow: hidden;
	position: relative;
}

.activity-2 .icon {
	width: 100%;
	transition: 0.3s 0.1s ease;
	cursor: help;
}

.activity-2:hover .icon {
	transform: translateY(-50px);
}

.activity-name {
	width: 100%;
	background: #1f80f9;
	/* padding: 0.5rem 0; */
	 border-radius: 5px 5px 0 0; 
	transition: 0.2s ease-in;
	text-align: center;
	opacity: 0;
}
.activity-2:hover .activity-name { opacity: 1; }