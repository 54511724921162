/**
 * Park Marker CSS
 */

.map-marker-photo {
	width: 200px;
	height: auto;
	padding: 0;
	margin: 0;
}
.map-marker-photo img {
	width: 100%;
}