/**
 * Responsive Park Styles
 */

/*@media screen (max-width: 400px) {*/
@media only screen
	and (min-device-width : 375px)
	and (max-device-width : 667px){

	.park-details .park-header,
	.park-details .trip-info {
		flex-direction: column;
	}
	.park-details .trip-info .visit-details {
		width: 100%;
	}

	.park-details .trip-info .activity-list {
		margin-top: 30px;
		width:100%;
	}
	.park-details .photo-gallery { margin: 0; }

	.park-details .park-map {
		display: flex;
		flex-direction: column;
	}
	.park-details .park-map p { order:2; }
	.park-details .react-leaflet-map {
		width:100%;
		height: 400px;
		margin: 20px 0;
	}
	.park-details .leaflet-container {
		height: 400px;
		width: 100%;
		margin: 0 auto;
	}
}

/*@media only screen
	and (min-width: 1488px){
		.trip-info .visit-details {	width: 60%; }
		.trip-info .activity-list { width: 30%; }
	}*/