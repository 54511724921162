/**
 * NavGroup.css
 */

.nav-group {
	width:30vw;
	border:2px solid transparent;
	border-radius: 1rem;
	padding: 1rem;
	transition: 0.75s;
	cursor: pointer;
}

.nav-group.is-expanded {
	width:100%;
	cursor: default;
}

.nav-group.is-expanded h3{
	cursor: pointer;
}

.nav-group:hover {
	border-color: #fff;
}

.nav-group strong {
	padding-right: 1rem;
}

/**
 * Sub Groups
 */
.nav-group .album-items {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.nav-group .album-items .nav-item {
	min-width:10vw;
	margin:1rem;
	text-align: center;
}

.nav-group .nav-item.collection {
	width:50%;
	position: relative;
	z-index: 5;
	/*border:3px solid rgb(85,236,97);*/
	color:rgb(109,21,0);
	padding-top: 1rem;
	font-weight: bold;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;

	background: #34aeb6;
	padding: 10px;
	border-radius: 10px;
	border: 5px solid;
}


.nav-item.medium-collection {
	min-width:40%;
}

.nav-item.wide-collection {
	min-width:60%;
}

.nav-item.collection > .album-items {
	justify-content: center;
	/*background-color:rgb(85,236,97);*/
	color:rgb(109,21,0);
	width:calc(100% - 135px);
	position:relative;
	margin-top: auto;
	/*box-shadow:0 1px 4px rgba(0, 0, 0, 0.3)
		, 0 0 40px rgba(0, 0, 0, 0.1) inset;*/
}

.nav-item.collection > .album-items::before,
.nav-item.collection > .album-items::after {
	content:"";
	position:absolute;
	z-index:-1;
	/*box-shadow:0 0 20px rgba(0,0,0,0.8);*/
	top:10px;
	bottom:10px;
	left:0;
	right:0;
	border-radius:100px / 10px;
}

.nav-item.collection > .album-items::after {
	right:10px;
	left:auto;
	transform:skew(8deg) rotate(3deg);
}

.nav-group > .album-items > article.nav-item.album {
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	/*margin:20px;*/
	padding: 10px;
}

@media screen and (max-width: 700px) {
	.nav-group {
		width: 100%;
		margin: 1rem 0;
		border-bottom: 1px solid #003804;
	}

	.nav-group > .album-items > article.nav-item.album,
	.nav-group .nav-item.collection {
		width:45%;
		margin: 15px 0;
	}

	.nav-group .album-items {
		justify-content: space-between;
	}
}