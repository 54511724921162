
body {
  margin: 0;
  padding: 0;
}

::selection {
	background-color: #197302;
	color: #fff;
}

@font-face{
	font-family: StateFace;
	src: url("//static.alphavega.com/fonts/stateface-regular-webfont.woff");
}

.state-icon {
	font-family: StateFace;
	font-size: 20px;
	color: #0f520f;
}

.the-list {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
}

.the-list .collection-item a {
	text-decoration: none;
	color:#BC412B;
}

/**
 * Icons
 */
.svg-icon {
	width:20px;
	height: 20px;
	display: block;
}
.svg-icon.photo-album {
	background-image: url("//static.alphavega.com/images/svgs/photo_album.svg");
	background-repeat: no-repeat;
	background-position: 50% 50%;
}
