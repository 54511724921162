/**
 * 404 CSS
 */
.four-0-4-back {
	background-image:url(https://steve.deadlycomputer.com/dims/large/And%20Beyond_RCM_1250-Edit_Stephen%20Giorgi.jpg);
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	display: flex;
	align-items: center;
	justify-content: center;
}
.lost-header {
	font-size: 100px;
	margin: 0;
	font-family:"lemonchickenregular";
	font-weight: bold;
	font-size: 48px;
	animation: lost 5s;
	animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes lost {
	0% {
		transform:none;
	}

	100% {
		transform: rotate(360deg);
	}
}