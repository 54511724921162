/**
 * Navigation.css
 */

.showing-nav {
	overflow: hidden;
}

.Zion-Navigation {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;
}

.Zion-Navigation.is-open {
	z-index: 10000;
	width: 100vw;
	height: 100vh;
}

.Zion-Navigation .navigation-holder {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: flex-start;
	overflow-y: scroll;

	/*background-color:rgb(0, 165, 13);*/
	background-color:#BC412B;
	/*background:linear-gradient(to bottom
					, rgba(1, 53, 117, 0.9)
					, rgba(31, 128, 249, 0.9))
				;*/

	color:#fff;
	width: 0;
	height: 0;
	position: absolute;
	top: 0;
	left: 0;
	padding: 5rem;
	transition: 0.5s height
		, 0.5s width
		, 0.1s opacity ease-out;
	opacity: 0;
	pointer-events: none;
}

.Zion-Navigation.is-open .navigation-holder {
	/*width:calc(100vw - 10rem);
	height: calc(100vh - 10rem);*/
	width:100vw;
	height: 100vh;
	opacity: 1;
	pointer-events: all;
}

/**
 * Button
 */
 .button-grid {
 	padding: 2rem 1.6rem 2rem 1.4rem;
 	cursor: pointer;
 	user-select: none;
 	display: inline-block;
 	margin: 0 1em 0 0;
 	border: none;
 	background-color: transparent;
 	z-index: 17000000;
 	transition-duration: 0.75s;
 	position: relative;
 	z-index: 100;
 	margin-left: 1rem;
 }

 .button-grid .square-grid {
 	width: 1rem;
 	height: 1rem;
 	background: #BC412B;
 	color: #BC412B;
 	display: block;
 	transition: 0.3s;
 }
 .button-grid:hover .square-grid {
 	background-color: #006494;
 	color:#13293D;
 }

 .button-grid.collapse .square-grid {
 	box-shadow: -1.25rem 0
 		, -1.25rem 1.25rem
 		, 1.25rem 0
 		, 1.25rem -1.25rem
 		, 0 -1.25rem
 		, -1.25rem -1.25rem
 		, 0 1.25rem
 		, 1.25rem 1.25rem;
 }

 .is-open .button-grid.collapse .square-grid {
 	box-shadow: -1rem 0
 		, 0 0 transparent
 		, 1rem 0
 		, 0 0 transparent
 		, 0 -1rem
 		, 0 0 transparent
 		, 0 1rem
 		, 0 0 transparent;
 	transform: rotate3d(0,0,1,-45deg) scale3d(.8,.8,.8);
 	color:#fff;
 	background-color: #fff;
 }


/**
 * Static Links
 */

.static-nav-item {
	width: 27vw;
	height: 275px;
	margin: 1rem;
	border: 1px solid;
	padding: 2rem;
	transition: 0.5s ease-in;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.static-nav-item:hover {
	background-color: rgba(2, 24, 51, 1);
	border-color: rgba(2, 24, 51, 1);
	border-radius: 2rem;
	box-shadow: 2px 2px 2px #fff;
}

.static-nav-item .icon-side {
	width:100px;
	margin:0px 20px 0 0;
	text-align: center;
}

.static-nav-item .icon-side .fa {
	font-size: 80px;
}

.static-nav-item img {
	width: 100%;
	transform: rotate(90deg);
	display: block;
	height: 100%;
}

.static-nav-item .info-side {
	max-width:calc(100% - 115px);
}

.static-nav-item.about-item .info-side {
	width:225px;
}

.static-nav-item.america-item {
	width:40vw;
}

.static-nav-item.usage-item,
.static-nav-item.about-item {
	width:36vw;
}
.static-nav-item.new-zealand { width:40vw; }
.static-nav-item.new-zealand .icon-side img {
	transform: rotate(0);
}

/**
 * Nav Item
 */
.nav-item .photo-album {
	width:80px;
	height: 80px;
}
.nav-item .item-album {
	margin-left: 15px;
}
.nav-item .total-nav {
	font-size: 30px;
	color:#E8F1F2;
	margin:0;
	margin-right: 15px;
	display: flex;
	justify-content: center;
}

/**
 * General Styles
 */
/**
 * Links
 */
.navigation-holder a {
	color: #13293D;
	font-weight: bold;
	text-decoration: none;
	box-shadow: 0 1px;
	transition: 0.5s;
	width:100%;
}

.navigation-holder a:hover {
	color:#c7f3ef;
}

.navigation-holder h3 {
	font-family: "lemonchickenregular";
	font-weight: normal;
	font-size: 2.5rem;
	margin: 0;
	text-align: center;
}

/*.navigation-holder .collection > .album-items a {
	color: #1f80f9;
	text-shadow: -4px -1px 10px #acd8ff;
}*/

@media screen and (max-width: 700px) {
	.Zion-Navigation .navigation-holder {
		padding:0.5rem;
	}

	.navigation-holder .static-nav-item.usage-item,
	.navigation-holder .static-nav-item.about-item,
	.navigation-holder .static-nav-item {
		width: 100vw;
		height: auto;
	}

	.static-nav-item .info-side {
		width:100%;
		text-align: center;
		max-width: 100%;
		font-size: 14px;
	}

	.static-nav-item.america-item .icon-side { display: none; }

	.static-nav-item .info-side p,
	.static-nav-item .info-side a {
		margin-top: 1rem;
	}

	.nav-group {
		font-size: 16px;
		margin: 10px;
		border: 1px solid;
	}

	.static-nav-item .icon-side { width:50px; }
	.static-nav-item .icon-side .fa {
		font-size: 50px;
	}

	.nav-item .total-nav {
		font-size: 22px;
	}

	.nav-item .photo-album {
		width: 40px;
		height: 40px;
	}
}