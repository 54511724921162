/**
 * Footer.css
 *
 * Styles for the footer
 */

#rainier-footer {
	min-height: 400px;
	width: calc(100% - 4px);
	border-top: 2px solid #000;
	background-image: url("//static.alphavega.com/images/svgs/black_noize.svg");
	background-repeat: repeat;
	background-color: #424242;
	padding: 2rem 2rem 1rem;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
}

#rainier-footer aside {
	width: 40vw;
	border-bottom: 1px solid;
	margin: 2rem;
	color:#fff;
}


#rainier-footer h4 {
	font-family: "lemonchickenregular";
	text-align: center;
	border-bottom: 1px solid;
	margin:0;
	padding: 0;
}

#rainier-footer ul {
	padding: 2rem 0 2rem 4rem;
	margin: 0;
}

#rainier-footer a {
	color: #5ee0ef;
	font-weight: normal;
	line-height: 1.4;
	border-bottom: 1px solid;
	transition: 0.3s ease-out;
}
#rainier-footer a:hover {
	color:#bc412b;
}

#rainier-footer i {
	margin-right: 1rem;
	width: 2rem;
}

.stats-section label {
	color: #2e3233;
	text-shadow: 0px 0 2px white;
	font-weight: bold;
}
.stats-section b {
	margin-left: 1rem;
}

.html5-info li::before {
	content: " ";
	display: inline-block;
	margin-right: 1rem;
	height: 20px;
	width: 20px;
	background-repeat: no-repeat;
	transform:			translate(0,6px);
	transition: 		0.3s;
}

.html5-info .semantics::before {
	background-image: url("//static.alphavega.com/images/svgs/html5_semantics.svg");
}
.html5-info .css3::before {
	background-image: url("//static.alphavega.com/images/svgs/html5_css3_styling.svg");
}
.html5-info .ddd::before {
	background-image: url("//static.alphavega.com/images/svgs/html5_3d_effects.svg");
}
.html5-info .storage::before {
	background-image: url("//static.alphavega.com/images/svgs/html5_offline_storage.svg");
}
.html5-info li:hover::before {
	transform: translate(30rem,6px);
}

/**
 * Media Queries
 */

@media screen and (max-width: 750px) {
	#rainier-footer aside {
		width:70vw;
		margin: 1rem 0;
	}

	#rainier-footer h4 {
		margin: 1rem;
	}
}

@media screen and (max-width: 400px) {
	#rainier-footer aside {
		width:95vw;
	}
}