/**
 */
.three-60-photo-gallery {
	margin:20px 0;
}

.three-60-photo-gallery h2 {
	text-align: center;
	font-family: "lemonchickenregular";
	font-size: 30px;
	color: #0f520f;
	margin: 0;
	text-align: center;
	border-bottom: 1px solid;
}

.three-60-photo-gallery .photo-list {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
}
.three-60-photo-gallery .photo-list .three-30-thumb {
	width:300px;
	height: 150px;
	margin:15px 10px;
	border-radius: 9px;
	cursor: zoom-in;
}