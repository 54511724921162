/**
 * Photo Stack
 */

.album-item .photo-stack {
	position: relative;
	margin: 0 auto;
	width: 150px;
	height: 150px;
	padding: 0;
	list-style: none;
	-webkit-transition: .5s ease-in;
	-moz-transition: .5s ease-in;
	transition: .5s ease-in;
}

.photo-stack li {
	position: absolute;
	z-index: 9;
	transition: .25s ease-in;
	transform-origin: 100% 0;
}

.photo-stack li:first-child {
	z-index: 10;
}

.photo-stack img {
	border-radius: 3px;
	width: 150px;
	height: 150px;
}

/**
 * Photo Stack Hover
 */

.photo-stack:hover li {
	transition: .2s ease-out 0;
}

.photo-stack:hover li:nth-child(2) {
	transition-delay: .05s;
	transform: translate3d(-50%,-87px,-10px) rotate(-21deg);
	box-shadow: -2px -2px 5px rgba(58,58,58,.7);
}

.photo-stack:hover li:nth-child(3) {
	transition-delay: .1s;
	transform: translate3d(50%,-57px,-10px) rotate(13deg);
	transform: 50% 75%;
	box-shadow: 2px -2px 5px rgba(58,58,58,.7);
}

.photo-stack:hover li:nth-child(4) {
	transition-delay: .15s;
	transform: translate3d(50%,30px,-10px) rotate(-20deg);
	box-shadow: 2px 2px 5px rgba(58,58,58,.7);
}

.photo-stack:hover li:nth-child(5) {
	transition-delay: .2s;
	transform: translate3d(-50%,75px,-4px) rotate(30deg);
	transform-origin: 50% 50%;
	box-shadow: 2px 2px 5px rgba(255,255,255,.5);
}