
.next-photo {
	height: 100%;
	display: flex; 
	align-items: center; 
	justify-content: center;
	width: 30px;
	font-size: 35px;
}

.next-photo-control:not(.is-disabled):hover {
	background-color:rgba(188, 65, 43, 0.7);
}
.next-photo-controlnot(.is-disabled):hover .next-photo {
	color:rgb(188, 65, 43);
}

.next-photo-control.is-disabled {
	cursor: not-allowed;
}
.next-photo-control.is-disabled .next-photo { opacity: 0.4; }