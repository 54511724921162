
.album-list {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
}

.album-item {
	display: flex;
	width: 500px;
	margin: 10px;
	align-items: center;
}
.album-item header {
	width:calc(100% - 175px);
	margin-right: 5px;
	line-height: 1.5;
}

.album-item .collection-name a {
	color: #0d838a;
	text-decoration: none;
	text-shadow: -1px -1px 0 #524a4a;
	transition: 0.2s ease;
}
.album-item .collection-name a:hover {
	text-shadow: 2px 2px 2px #251e1e;
	color: #000;
}

/**
 * Collection Styles
 */

.collection-item {
	width: 50vw;
	flex-direction: column;
	justify-content: center;
	margin: 20px 0;
}
.collection-name {
	font-family:"lemonchickenregular";
	font-weight: bold;
	font-size: 20px;
	color:#020a23;
	margin: 0;
	text-align: center;
}


.collection-item .total-albums strong { margin-right: 10px; }


/**
 * @media Queries
 */

@media screen and (min-width: 1500px) {
	.album-item {
		width:600px;
	}
	.album-item:nth-child(even) .item-header {
		order: 2;
		margin-left: 10px;
	}
}

@media screen and (max-width: 1100px) {
	.album-item {
		font-size: 16px;
	}
}