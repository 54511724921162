/**
 * Review Day Styles
 */
.review-day .review-body p:first-child:first-letter {
	color: #173e8a;
	font-size: 46px;
	line-height: 1.2;
	padding-top: 0px;
	padding-right: 5px;
	padding-left: 0px;
	float: left;
	font-family: lemonchicken;
	font-weight: bold;
	margin-top: -10px;
}

.day-rating {
	margin-top:25px;
	padding-top: 20px;
	border-top: 2px solid #173e8a;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.day-rating p {
	margin:0 0 10px;
}

.day-activities {
	display: flex;
	flex-direction: column;
}
.day-activity-list .activity-2 {
	font-size: 14px;
}
.day-activity-list .activity-2 .icon {
	width: 50px;
	margin:0 10px;
}