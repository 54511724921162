
.park-map {
	
}
.leaflet-container {
	height: 600px;
	width: 80%;
	margin: 0 auto;
}
/**
 * Very Important CSS needed to get the map working
 **/
.react-leaflet-map {
	/*width:100%;*/
	height:600px;
}

.park-map .leaflet-container {
	z-index: 9;
}

.park-map p {
	margin: 0 auto 10px;
	width: 80%;
}
.park-map .map-park-name {
	font-weight: bold;
	color:#B098A4;
	border-bottom: 1px dotted;
}



@media screen and (max-width: 400px) {
	.react-leaflet-map {
		width:100%;
		margin:0;
	}
	.leaflet-container { width:100%; }
}
