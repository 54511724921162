/**
 * Photo Styles
 */

.photo-details {
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
}

.photo-details .main-photo {
	display:flex;
	margin:0;
}

.photo-details .main-photo img { max-height: 90vh; }

.photo-details .caption {
	margin-left:50px;
	width: 100%;
}

.photo-details h1 {
	font-family: "lemonchickenregular";
	font-weight: normal;
	font-size: 3.5rem;
	margin:30px 0;
	text-align: center;
}


/**
 * EXIF info
 */
.exif-item .custom-icon {
	width: 25px;
	height: 25px;
	display: block;
	background-size: 25px;
}

.custom-icon.aperature-icon {
	background-image: url(https://static.alphavega.com/images/svgs/aperature.svg);
}
.custom-icon.camera-timer-icon {
	background-image: url(https://static.alphavega.com/images/svgs/camera-timer.svg);
}
.custom-icon.film-container-icon {
	background-image: url(https://static.alphavega.com/images/svgs/film_container.svg);
}
.custom-icon.camera-lens-icon {
	background-image: url(https://static.alphavega.com/images/svgs/camera_lens.svg);
}