.park-header {
	display: flex;
	justify-content: space-between;
	margin-bottom: 30px;
}

.name-side {
	display: flex;

}

.album-name,
.park-name {
	font-family:"lemonchickenregular";
	font-weight: normal;
	font-size: 60px;
	color:#173e8a;
	margin: 0;
	margin-right: 10px;
}


.est::before {
	content:"Est.";
	margin-right: 5px;
}


.park-header .info-side {
	text-align: right;
	line-height: 1.6;
	margin-right: 15px;
}

.park-header .states {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
.park-header .state-icon {
	font-size: 40px;
	margin-left: 10px;
}

@media screen and (max-width: 700px) {
	.album-name
	.park-name, {
		font-size: 42px;
	}

	.park-header .info-side {
		text-align: center;
	}
	.park-header .states { justify-content: center; text-align: center; }
}