.the-timelapse {}

.timelapse-list {
	display:flex;
	flex-wrap: wrap;
	justify-content: space-around;
}
.timelapse-list .time-thumb {
	width: 25vw;
}

.time-thumb img {
	width:300px;
	height: auto;
	transition: 0.3s border-radius ease;
}

.time-thumb-link {
	text-decoration: none;
	text-align: center;
	color:inherit;
	display: flex;
	align-content:center;
}
.time-thumb-link:hover .time-thumb img {
	border-radius: 15px;
}