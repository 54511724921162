/**
 * MyStats.css
 *
 */

.park-stats h2 {
	margin-bottom: 0;
	font-family:"lemonchickenregular";
	font-weight: bold;
	font-size: 28px;
	color:#020a23;
}

.park-boxes {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-template-rows: repeat(2, 1fr);
	grid-column-gap: 0px;
	grid-row-gap: 0px;
}

.park-boxes .total-park-count {
	grid-area: 1 / 1 / 3 / 2;
}

.park-boxes .park-activity {
	width: 10vw;
	/*height: 15rem;*/
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.park-activity h3 {
	margin: 1rem 0;
}
.park-activity h4 {
	margin: 1rem 0;
	order:3;
}

.park-activity img {
	height:10rem;
	width:10rem;
	margin: 0 auto;
}

@media screen and (max-width:400px) {
	.park-boxes {
		justify-content: space-around;
	}
	.park-boxes .park-activity {
		width:40vw;
	}
}