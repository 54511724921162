
body {
  margin: 0;
  padding: 0;
}

::-moz-selection {
	background-color: #197302;
	color: #fff;
}

::selection {
	background-color: #197302;
	color: #fff;
}

@font-face{
	font-family: StateFace;
	src: url("//static.alphavega.com/fonts/stateface-regular-webfont.woff");
}

.state-icon {
	font-family: StateFace;
	font-size: 20px;
	color: #0f520f;
}

.the-list {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
}

.the-list .collection-item a {
	text-decoration: none;
	color:#BC412B;
}

/**
 * Icons
 */
.svg-icon {
	width:20px;
	height: 20px;
	display: block;
}
.svg-icon.photo-album {
	background-image: url("//static.alphavega.com/images/svgs/photo_album.svg");
	background-repeat: no-repeat;
	background-position: 50% 50%;
}

/**
 * NavGroup.css
 */

.nav-group {
	width:30vw;
	border:2px solid transparent;
	border-radius: 1rem;
	padding: 1rem;
	-webkit-transition: 0.75s;
	transition: 0.75s;
	cursor: pointer;
}

.nav-group.is-expanded {
	width:100%;
	cursor: default;
}

.nav-group.is-expanded h3{
	cursor: pointer;
}

.nav-group:hover {
	border-color: #fff;
}

.nav-group strong {
	padding-right: 1rem;
}

/**
 * Sub Groups
 */
.nav-group .album-items {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.nav-group .album-items .nav-item {
	min-width:10vw;
	margin:1rem;
	text-align: center;
}

.nav-group .nav-item.collection {
	width:50%;
	position: relative;
	z-index: 5;
	/*border:3px solid rgb(85,236,97);*/
	color:rgb(109,21,0);
	padding-top: 1rem;
	font-weight: bold;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;

	background: #34aeb6;
	padding: 10px;
	border-radius: 10px;
	border: 5px solid;
}


.nav-item.medium-collection {
	min-width:40%;
}

.nav-item.wide-collection {
	min-width:60%;
}

.nav-item.collection > .album-items {
	justify-content: center;
	/*background-color:rgb(85,236,97);*/
	color:rgb(109,21,0);
	width:calc(100% - 135px);
	position:relative;
	margin-top: auto;
	/*box-shadow:0 1px 4px rgba(0, 0, 0, 0.3)
		, 0 0 40px rgba(0, 0, 0, 0.1) inset;*/
}

.nav-item.collection > .album-items::before,
.nav-item.collection > .album-items::after {
	content:"";
	position:absolute;
	z-index:-1;
	/*box-shadow:0 0 20px rgba(0,0,0,0.8);*/
	top:10px;
	bottom:10px;
	left:0;
	right:0;
	border-radius:100px / 10px;
}

.nav-item.collection > .album-items::after {
	right:10px;
	left:auto;
	-webkit-transform:skew(8deg) rotate(3deg);
	        transform:skew(8deg) rotate(3deg);
}

.nav-group > .album-items > article.nav-item.album {
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	/*margin:20px;*/
	padding: 10px;
}

@media screen and (max-width: 700px) {
	.nav-group {
		width: 100%;
		margin: 1rem 0;
		border-bottom: 1px solid #003804;
	}

	.nav-group > .album-items > article.nav-item.album,
	.nav-group .nav-item.collection {
		width:45%;
		margin: 15px 0;
	}

	.nav-group .album-items {
		justify-content: space-between;
	}
}
/**
 * Navigation.css
 */

.showing-nav {
	overflow: hidden;
}

.Zion-Navigation {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;
}

.Zion-Navigation.is-open {
	z-index: 10000;
	width: 100vw;
	height: 100vh;
}

.Zion-Navigation .navigation-holder {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: flex-start;
	overflow-y: scroll;

	/*background-color:rgb(0, 165, 13);*/
	background-color:#BC412B;
	/*background:linear-gradient(to bottom
					, rgba(1, 53, 117, 0.9)
					, rgba(31, 128, 249, 0.9))
				;*/

	color:#fff;
	width: 0;
	height: 0;
	position: absolute;
	top: 0;
	left: 0;
	padding: 5rem;
	-webkit-transition: 0.5s height
		, 0.5s width
		, 0.1s opacity ease-out;
	transition: 0.5s height
		, 0.5s width
		, 0.1s opacity ease-out;
	opacity: 0;
	pointer-events: none;
}

.Zion-Navigation.is-open .navigation-holder {
	/*width:calc(100vw - 10rem);
	height: calc(100vh - 10rem);*/
	width:100vw;
	height: 100vh;
	opacity: 1;
	pointer-events: all;
}

/**
 * Button
 */
 .button-grid {
 	padding: 2rem 1.6rem 2rem 1.4rem;
 	cursor: pointer;
 	-webkit-user-select: none;
 	   -moz-user-select: none;
 	    -ms-user-select: none;
 	        user-select: none;
 	display: inline-block;
 	margin: 0 1em 0 0;
 	border: none;
 	background-color: transparent;
 	z-index: 17000000;
 	-webkit-transition-duration: 0.75s;
 	        transition-duration: 0.75s;
 	position: relative;
 	z-index: 100;
 	margin-left: 1rem;
 }

 .button-grid .square-grid {
 	width: 1rem;
 	height: 1rem;
 	background: #BC412B;
 	color: #BC412B;
 	display: block;
 	-webkit-transition: 0.3s;
 	transition: 0.3s;
 }
 .button-grid:hover .square-grid {
 	background-color: #006494;
 	color:#13293D;
 }

 .button-grid.collapse .square-grid {
 	box-shadow: -1.25rem 0
 		, -1.25rem 1.25rem
 		, 1.25rem 0
 		, 1.25rem -1.25rem
 		, 0 -1.25rem
 		, -1.25rem -1.25rem
 		, 0 1.25rem
 		, 1.25rem 1.25rem;
 }

 .is-open .button-grid.collapse .square-grid {
 	box-shadow: -1rem 0
 		, 0 0 transparent
 		, 1rem 0
 		, 0 0 transparent
 		, 0 -1rem
 		, 0 0 transparent
 		, 0 1rem
 		, 0 0 transparent;
 	-webkit-transform: rotate3d(0,0,1,-45deg) scale3d(.8,.8,.8);
 	        transform: rotate3d(0,0,1,-45deg) scale3d(.8,.8,.8);
 	color:#fff;
 	background-color: #fff;
 }


/**
 * Static Links
 */

.static-nav-item {
	width: 27vw;
	height: 275px;
	margin: 1rem;
	border: 1px solid;
	padding: 2rem;
	-webkit-transition: 0.5s ease-in;
	transition: 0.5s ease-in;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.static-nav-item:hover {
	background-color: rgba(2, 24, 51, 1);
	border-color: rgba(2, 24, 51, 1);
	border-radius: 2rem;
	box-shadow: 2px 2px 2px #fff;
}

.static-nav-item .icon-side {
	width:100px;
	margin:0px 20px 0 0;
	text-align: center;
}

.static-nav-item .icon-side .fa {
	font-size: 80px;
}

.static-nav-item img {
	width: 100%;
	-webkit-transform: rotate(90deg);
	        transform: rotate(90deg);
	display: block;
	height: 100%;
}

.static-nav-item .info-side {
	max-width:calc(100% - 115px);
}

.static-nav-item.about-item .info-side {
	width:225px;
}

.static-nav-item.america-item {
	width:40vw;
}

.static-nav-item.usage-item,
.static-nav-item.about-item {
	width:36vw;
}
.static-nav-item.new-zealand { width:40vw; }
.static-nav-item.new-zealand .icon-side img {
	-webkit-transform: rotate(0);
	        transform: rotate(0);
}

/**
 * Nav Item
 */
.nav-item .photo-album {
	width:80px;
	height: 80px;
}
.nav-item .item-album {
	margin-left: 15px;
}
.nav-item .total-nav {
	font-size: 30px;
	color:#E8F1F2;
	margin:0;
	margin-right: 15px;
	display: flex;
	justify-content: center;
}

/**
 * General Styles
 */
/**
 * Links
 */
.navigation-holder a {
	color: #13293D;
	font-weight: bold;
	text-decoration: none;
	box-shadow: 0 1px;
	-webkit-transition: 0.5s;
	transition: 0.5s;
	width:100%;
}

.navigation-holder a:hover {
	color:#c7f3ef;
}

.navigation-holder h3 {
	font-family: "lemonchickenregular";
	font-weight: normal;
	font-size: 2.5rem;
	margin: 0;
	text-align: center;
}

/*.navigation-holder .collection > .album-items a {
	color: #1f80f9;
	text-shadow: -4px -1px 10px #acd8ff;
}*/

@media screen and (max-width: 700px) {
	.Zion-Navigation .navigation-holder {
		padding:0.5rem;
	}

	.navigation-holder .static-nav-item.usage-item,
	.navigation-holder .static-nav-item.about-item,
	.navigation-holder .static-nav-item {
		width: 100vw;
		height: auto;
	}

	.static-nav-item .info-side {
		width:100%;
		text-align: center;
		max-width: 100%;
		font-size: 14px;
	}

	.static-nav-item.america-item .icon-side { display: none; }

	.static-nav-item .info-side p,
	.static-nav-item .info-side a {
		margin-top: 1rem;
	}

	.nav-group {
		font-size: 16px;
		margin: 10px;
		border: 1px solid;
	}

	.static-nav-item .icon-side { width:50px; }
	.static-nav-item .icon-side .fa {
		font-size: 50px;
	}

	.nav-item .total-nav {
		font-size: 22px;
	}

	.nav-item .photo-album {
		width: 40px;
		height: 40px;
	}
}
/**
 * Photo Stack
 */

.album-item .photo-stack {
	position: relative;
	margin: 0 auto;
	width: 150px;
	height: 150px;
	padding: 0;
	list-style: none;
	-webkit-transition: .5s ease-in;
	transition: .5s ease-in;
}

.photo-stack li {
	position: absolute;
	z-index: 9;
	-webkit-transition: .25s ease-in;
	transition: .25s ease-in;
	-webkit-transform-origin: 100% 0;
	        transform-origin: 100% 0;
}

.photo-stack li:first-child {
	z-index: 10;
}

.photo-stack img {
	border-radius: 3px;
	width: 150px;
	height: 150px;
}

/**
 * Photo Stack Hover
 */

.photo-stack:hover li {
	-webkit-transition: .2s ease-out 0;
	transition: .2s ease-out 0;
}

.photo-stack:hover li:nth-child(2) {
	-webkit-transition-delay: .05s;
	        transition-delay: .05s;
	-webkit-transform: translate3d(-50%,-87px,-10px) rotate(-21deg);
	        transform: translate3d(-50%,-87px,-10px) rotate(-21deg);
	box-shadow: -2px -2px 5px rgba(58,58,58,.7);
}

.photo-stack:hover li:nth-child(3) {
	-webkit-transition-delay: .1s;
	        transition-delay: .1s;
	-webkit-transform: translate3d(50%,-57px,-10px) rotate(13deg);
	        transform: translate3d(50%,-57px,-10px) rotate(13deg);
	-webkit-transform: 50% 75%;
	        transform: 50% 75%;
	box-shadow: 2px -2px 5px rgba(58,58,58,.7);
}

.photo-stack:hover li:nth-child(4) {
	-webkit-transition-delay: .15s;
	        transition-delay: .15s;
	-webkit-transform: translate3d(50%,30px,-10px) rotate(-20deg);
	        transform: translate3d(50%,30px,-10px) rotate(-20deg);
	box-shadow: 2px 2px 5px rgba(58,58,58,.7);
}

.photo-stack:hover li:nth-child(5) {
	-webkit-transition-delay: .2s;
	        transition-delay: .2s;
	-webkit-transform: translate3d(-50%,75px,-4px) rotate(30deg);
	        transform: translate3d(-50%,75px,-4px) rotate(30deg);
	-webkit-transform-origin: 50% 50%;
	        transform-origin: 50% 50%;
	box-shadow: 2px 2px 5px rgba(255,255,255,.5);
}

.album-list {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
}

.album-item {
	display: flex;
	width: 500px;
	margin: 10px;
	align-items: center;
}
.album-item header {
	width:calc(100% - 175px);
	margin-right: 5px;
	line-height: 1.5;
}

.album-item .collection-name a {
	color: #0d838a;
	text-decoration: none;
	text-shadow: -1px -1px 0 #524a4a;
	-webkit-transition: 0.2s ease;
	transition: 0.2s ease;
}
.album-item .collection-name a:hover {
	text-shadow: 2px 2px 2px #251e1e;
	color: #000;
}

/**
 * Collection Styles
 */

.collection-item {
	width: 50vw;
	flex-direction: column;
	justify-content: center;
	margin: 20px 0;
}
.collection-name {
	font-family:"lemonchickenregular";
	font-weight: bold;
	font-size: 20px;
	color:#020a23;
	margin: 0;
	text-align: center;
}


.collection-item .total-albums strong { margin-right: 10px; }


/**
 * @media Queries
 */

@media screen and (min-width: 1500px) {
	.album-item {
		width:600px;
	}
	.album-item:nth-child(even) .item-header {
		order: 2;
		margin-left: 10px;
	}
}

@media screen and (max-width: 1100px) {
	.album-item {
		font-size: 16px;
	}
}
.img-caption .title {
	font-family: "lemonchickenregular";
	font-size: 20px;
}

.swish-title a { text-decoration: none; }
.swish-title .title,
.time-thumb .img-caption .title {
	margin: 10px 00;
	border-bottom: 2px solid;
	border-top: 2px solid;
	padding: 5px;
	position: relative;
}

.time-thumb-link:hover .img-caption .title {
	color:hsl(208, 99%, 50%);
}

.swish-title .title::before,
.swish-title .title::after,
.img-caption .title::before,
.img-caption .title::after {
	content:"";
	position: absolute;
	width:0;
	height: 2px;
	background-color:hsl(208, 99%, 50%);
	-webkit-transition: 0.5s width ease-out;
	transition: 0.5s width ease-out;
}

.swish-title .title::before,
.img-caption .title::before {
	top: -2px;
	left: 0;
}
.swish-title .title::after,
.img-caption .title::after {
	bottom:-2px;
	right:0;
}

.swish-title:hover .title::before,
.swish-title:hover .title::after,
.time-thumb-link:hover .img-caption .title::before,
.time-thumb-link:hover .img-caption .title::after {
	width:100%;
	-webkit-transition: 0.5s width ease-out;
	transition: 0.5s width ease-out;
}

/*Rating Styles */

.rating-star {
	color:#c1c1c1;
	margin:0 2px;
}
.is-filled-in {
	color:#BC412B;
}
.park-header {
	display: flex;
	justify-content: space-between;
	margin-bottom: 30px;
}

.name-side {
	display: flex;

}

.album-name,
.park-name {
	font-family:"lemonchickenregular";
	font-weight: normal;
	font-size: 60px;
	color:#173e8a;
	margin: 0;
	margin-right: 10px;
}


.est::before {
	content:"Est.";
	margin-right: 5px;
}


.park-header .info-side {
	text-align: right;
	line-height: 1.6;
	margin-right: 15px;
}

.park-header .states {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
.park-header .state-icon {
	font-size: 40px;
	margin-left: 10px;
}

@media screen and (max-width: 700px) {
	.album-name
	.park-name, {
		font-size: 42px;
	}

	.park-header .info-side {
		text-align: center;
	}
	.park-header .states { justify-content: center; text-align: center; }
}
/**
 * Review Day Styles
 */
.review-day .review-body p:first-child:first-letter {
	color: #173e8a;
	font-size: 46px;
	line-height: 1.2;
	padding-top: 0px;
	padding-right: 5px;
	padding-left: 0px;
	float: left;
	font-family: lemonchicken;
	font-weight: bold;
	margin-top: -10px;
}

.day-rating {
	margin-top:25px;
	padding-top: 20px;
	border-top: 2px solid #173e8a;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.day-rating p {
	margin:0 0 10px;
}

.day-activities {
	display: flex;
	flex-direction: column;
}
.day-activity-list .activity-2 {
	font-size: 14px;
}
.day-activity-list .activity-2 .icon {
	width: 50px;
	margin:0 10px;
}
.react-tabs {
  -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
  border-bottom: 1px solid #aaa;
  margin: 0 0 10px;
  padding: 0;
}

.react-tabs__tab {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
}

.react-tabs__tab--selected {
  background: #fff;
  border-color: #aaa;
  color: black;
  border-radius: 5px 5px 0 0;
}

.react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}

.react-tabs__tab:focus {
  box-shadow: 0 0 5px hsl(208, 99%, 50%);
  border-color: hsl(208, 99%, 50%);
  outline: none;
}

.react-tabs__tab:focus:after {
  content: "";
  position: absolute;
  height: 5px;
  left: -4px;
  right: -4px;
  bottom: -5px;
  background: #fff;
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
}

/**
 * Activity.css
 */

.activity-list h4 {
	text-align: center;
	font-family: "lemonchickenregular";
	font-size: 30px;
	color:#0f520f;
	margin:0;
	text-align: center;
	border-bottom:1px solid;
}

.activity-list .activity-park-name {
	font-weight: bold;
	color:#B098A4;
	border-bottom: 1px dotted;
}

.activity-groups {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}
/*.activity {
	margin: 0 10px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	overflow: hidden;
	width: 10rem;
}
.activity-icon,
.activity img {
	width: 100%;
	height: 8rem;
	margin: 0 auto;
	text-align: center;
	display: block;
	transform: translateY(100%);
	/*transform: translateY(2rem);*/
	/*transition: 0.3s 0.1s ease;*/
	/*padding-bottom: 0.5rem;*/
/*}
*/
/*.activity-name {
	width: 100%;
	background: #1f80f9;
	padding: 0.5rem 0;
	border-radius: 5px 5px 0 0;
	transform: translateY(3.5rem);
	transition: 0.2s  ease-in;
}*/

/*.activity img { display: none; }*/
/*.activity-icon {
	background-color: #035010;
	width:100%;
	height: 9rem;
}

.activity:hover img,
.activity:hover .activity-icon,
.activity:hover .activity-name {
	transform: translateY(-3rem);
}
*/
/*.activity[data-activity-name="Rock Climbing"] .activity-name,
.activity[data-activity-name="Scenic Drive"] .activity-name {
	transform: translateY(6.25rem);
}
.activity[data-activity-name="Rock Climbing"]:hover .activity-name,
.activity[data-activity-name="Scenic Drive"]:hover .activity-name {
	transform: translateY(6.25rem);
}*/

.activity-distance {
	background-color: #fff;
	position: relative;
	z-index: 1;
}
.activity-distance b {
	padding-left: 0.5rem;
}

@media screen and (max-width: 800px) {
	.activity {
		width:7rem;
	}
	.activity img {
		max-height:5rem;
	}
	.activity-distance {
		font-size:1.5rem;
	}
}


/**
 * New Activity styles:;
 */
.activity-2 {
	width: 75px;
	margin: 5px 10px;
	display: flex;
	justify-content: flex-end;
	flex-direction: column;
	overflow: hidden;
	position: relative;
}

.activity-2 .icon {
	width: 100%;
	-webkit-transition: 0.3s 0.1s ease;
	transition: 0.3s 0.1s ease;
	cursor: help;
}

.activity-2:hover .icon {
	-webkit-transform: translateY(-50px);
	        transform: translateY(-50px);
}

.activity-name {
	width: 100%;
	background: #1f80f9;
	/* padding: 0.5rem 0; */
	 border-radius: 5px 5px 0 0; 
	-webkit-transition: 0.2s ease-in; 
	transition: 0.2s ease-in;
	text-align: center;
	opacity: 0;
}
.activity-2:hover .activity-name { opacity: 1; }
/**
 * Activity List
 */
.all-activity-list .day-activity-list {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-template-rows: repeat(5, 1fr);
	grid-column-gap: 5px;
	grid-row-gap: 5px;
}
@media screen and (min-width:1600px) and (max-width:1730px) {
	.all-activity-list .day-activity-list {
		grid-template-columns: repeat(5, 1fr);
		grid-template-rows: repeat(4, 1fr);
	}
}
@media screen and (min-width:1731px){
	.all-activity-list .day-activity-list {
		grid-template-columns: repeat(6, 1fr);
		grid-template-rows: repeat(4, 1fr);
	}
}


.all-activity-list .day-activity-list .activity-2 .icon{
	height: 100%;
	width: auto;
	margin: 0;
}

.all-activity-list .day-activity-list .activity-2:hover .icon {
	-webkit-transform: scale(0.75);
	        transform: scale(0.75);
}

.all-activity-list .activity-name {
	width: 100%;
	background: #1f80f9;
	border-radius: 5px 5px 0 0;
	-webkit-transition: 0.2s ease-in;
	transition: 0.2s ease-in;
	text-align: center;
	position: absolute;
	top: 50%;
	left: 0;
	margin-top: -20px;
	border-radius: 5px;
	font-size: 18px;
	-webkit-transform: scale(0);
	        transform: scale(0);
}

.all-activity-list .day-activity-list .activity-2:hover .activity-name {
	-webkit-transform: scale(1);
	        transform: scale(1);
}

.all-activity-list .activity-2:not(.done-today) .icon {
	-webkit-filter: blur(1px);
	        filter: blur(1px);
	opacity: 0.7;
}
/** Image Item CSS*/

.image img {
	width:100%;
	cursor:pointer;
}
.photo-gallery {
	margin:40px;
}

.photo-gallery .react-photo-gallery--gallery > div { justify-content: center; }



@media screen and (max-width: 400px) {
	.photo-gallery {
		margin:10px;
	}
}
.three-60-photo-thumb {
	width:400px;	
	display: flex;
	flex-direction: column;
	align-items: center;
}

.three-60-photo-thumb .title {
	text-align: center;
	cursor: pointer;	
}
/**
 */
.three-60-photo-gallery {
	margin:20px 0;
}

.three-60-photo-gallery h2 {
	text-align: center;
	font-family: "lemonchickenregular";
	font-size: 30px;
	color: #0f520f;
	margin: 0;
	text-align: center;
	border-bottom: 1px solid;
}

.three-60-photo-gallery .photo-list {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
}
.three-60-photo-gallery .photo-list .three-30-thumb {
	width:300px;
	height: 150px;
	margin:15px 10px;
	border-radius: 9px;
	cursor: -webkit-zoom-in;
	cursor: zoom-in;
}

.park-map {
	
}
.leaflet-container {
	height: 600px;
	width: 80%;
	margin: 0 auto;
}
/**
 * Very Important CSS needed to get the map working
 **/
.react-leaflet-map {
	/*width:100%;*/
	height:600px;
}

.park-map .leaflet-container {
	z-index: 9;
}

.park-map p {
	margin: 0 auto 10px;
	width: 80%;
}
.park-map .map-park-name {
	font-weight: bold;
	color:#B098A4;
	border-bottom: 1px dotted;
}



@media screen and (max-width: 400px) {
	.react-leaflet-map {
		width:100%;
		margin:0;
	}
	.leaflet-container { width:100%; }
}

/**
 * Park Styles
 */

 .trip-info {
	display: grid;
	grid-template-columns: 3fr 1fr;
	grid-template-rows: 1 1fr;
	grid-column-gap: 40px;
	grid-row-gap: 5px;
 }
 .trip-info .visit-details {
 	width:100%;
 }
 .trip-info .activity-list {
 	width:20%;
 	margin-left: 20px;
 }
.trip-info .visit-details .react-tabs__tab {
	-webkit-transition:0.3s ease;
	transition:0.3s ease;
}
.trip-info .visit-details .react-tabs__tab:hover {
	border-top: 2px solid;
}
.trip-info .visit-details .react-tabs__tab.react-tabs__tab--selected {
	background: #fff;
	border-color: #1865a7 !important;
	border-radius: 5px 5px 0 0;
	border-top: 5px solid;
	border-radius: 0;
	border-left-color: transparent !important;
	border-right-color: transparent !important;
}
/**
 * Responsive Park Styles
 */

/*@media screen (max-width: 400px) {*/
@media only screen
	and (min-device-width : 375px)
	and (max-device-width : 667px){

	.park-details .park-header,
	.park-details .trip-info {
		flex-direction: column;
	}
	.park-details .trip-info .visit-details {
		width: 100%;
	}

	.park-details .trip-info .activity-list {
		margin-top: 30px;
		width:100%;
	}
	.park-details .photo-gallery { margin: 0; }

	.park-details .park-map {
		display: flex;
		flex-direction: column;
	}
	.park-details .park-map p { order:2; }
	.park-details .react-leaflet-map {
		width:100%;
		height: 400px;
		margin: 20px 0;
	}
	.park-details .leaflet-container {
		height: 400px;
		width: 100%;
		margin: 0 auto;
	}
}

/*@media only screen
	and (min-width: 1488px){
		.trip-info .visit-details {	width: 60%; }
		.trip-info .activity-list { width: 30%; }
	}*/
.album-header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	border-bottom: 2px solid #173e8a;
	padding-bottom: 20px;
}

.album-info {
	width:45%;
	display: flex;
	flex-direction: column;
}




@media screen and (max-width: 400px) {
	.album-header {
		flex-direction: column;
	}

	.album-info {
		width: 100%;
	}
}
/**
 * MyStats.css
 *
 */

.park-stats h2 {
	margin-bottom: 0;
	font-family:"lemonchickenregular";
	font-weight: bold;
	font-size: 28px;
	color:#020a23;
}

.park-boxes {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-template-rows: repeat(2, 1fr);
	grid-column-gap: 0px;
	grid-row-gap: 0px;
}

.park-boxes .total-park-count {
	grid-area: 1 / 1 / 3 / 2;
}

.park-boxes .park-activity {
	width: 10vw;
	/*height: 15rem;*/
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.park-activity h3 {
	margin: 1rem 0;
}
.park-activity h4 {
	margin: 1rem 0;
	order:3;
}

.park-activity img {
	height:10rem;
	width:10rem;
	margin: 0 auto;
}

@media screen and (max-width:400px) {
	.park-boxes {
		justify-content: space-around;
	}
	.park-boxes .park-activity {
		width:40vw;
	}
}
/**
 * Park Marker CSS
 */

.map-marker-photo {
	width: 200px;
	height: auto;
	padding: 0;
	margin: 0;
}
.map-marker-photo img {
	width: 100%;
}
/**
 * USA Styles
 */
.state-header.usa-header h2 {
	font-family:"lemonchickenregular";
	font-weight: bold;
	font-size: 36px;
	color:#020a23;
}
/**
 * About Sizing
 */
 .about-holder h1,
 .about-holder h2,
 .about-holder h3 {
	font-family:"lemonchickenregular";
	font-weight: normal;
	font-size: 60px;
	color:#173e8a;
	margin: 0;
	margin-right: 10px;
}
.about-holder h2 {
	font-size: 40px;
	color:#BC412B;
}

.about-holder h3 {
	font-size: 30px;
	color:#BC412B;
}

/**
 * ParkInfo.css
 */
.photo-park-info {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 80px;
	box-shadow: 1px -34px 3px -34px #020a23;
	padding-top: 20px;
	width: 50%;
	text-align: center;
	align-self: center;
}
.photo-park-info .park-name {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.photo-park-info h3 {
	font-family: "lemonchickenregular";
	font-weight: normal;
	font-size: 2rem;
	/*margin:30px 0;*/
	text-align: center;
}

.photo-park-info .rating {
	font-weight: bold;
}
/**
 * Photo Styles
 */

.photo-details {
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
}

.photo-details .main-photo {
	display:flex;
	margin:0;
}

.photo-details .main-photo img { max-height: 90vh; }

.photo-details .caption {
	margin-left:50px;
	width: 100%;
}

.photo-details h1 {
	font-family: "lemonchickenregular";
	font-weight: normal;
	font-size: 3.5rem;
	margin:30px 0;
	text-align: center;
}


/**
 * EXIF info
 */
.exif-item .custom-icon {
	width: 25px;
	height: 25px;
	display: block;
	background-size: 25px;
}

.custom-icon.aperature-icon {
	background-image: url(https://static.alphavega.com/images/svgs/aperature.svg);
}
.custom-icon.camera-timer-icon {
	background-image: url(https://static.alphavega.com/images/svgs/camera-timer.svg);
}
.custom-icon.film-container-icon {
	background-image: url(https://static.alphavega.com/images/svgs/film_container.svg);
}
.custom-icon.camera-lens-icon {
	background-image: url(https://static.alphavega.com/images/svgs/camera_lens.svg);
}

.next-photo {
	height: 100%;
	display: flex; 
	align-items: center; 
	justify-content: center;
	width: 30px;
	font-size: 35px;
}

.next-photo-control:not(.is-disabled):hover {
	background-color:rgba(188, 65, 43, 0.7);
}
.next-photo-controlnot(.is-disabled):hover .next-photo {
	color:rgb(188, 65, 43);
}

.next-photo-control.is-disabled {
	cursor: not-allowed;
}
.next-photo-control.is-disabled .next-photo { opacity: 0.4; }
.time-lapse-focused {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;	
}

.time-lapse-focused .video-figure {
	display:flex;
	flex-direction:column;
	justify-content:center;
}

.video-figure .time-video {
	width:auto;
	height: 35vw;
}

.time-lapse-focused .next-photo-control {
	width:50px;
	height: 100%;
	margin-right: -20px;
	height: 85vh;
	-webkit-transition: 0.4s ease;
	transition: 0.4s ease;
	cursor: pointer;
}
.time-lapse-focused .next-photo-control.is-previous {
	margin-left: -20px;
	margin-right: 0;
}

.the-timelapse {}

.timelapse-list {
	display:flex;
	flex-wrap: wrap;
	justify-content: space-around;
}
.timelapse-list .time-thumb {
	width: 25vw;
}

.time-thumb img {
	width:300px;
	height: auto;
	-webkit-transition: 0.3s border-radius ease;
	transition: 0.3s border-radius ease;
}

.time-thumb-link {
	text-decoration: none;
	text-align: center;
	color:inherit;
	display: flex;
	align-content:center;
}
.time-thumb-link:hover .time-thumb img {
	border-radius: 15px;
}
/**
 * 404 CSS
 */
.four-0-4-back {
	background-image:url(https://steve.deadlycomputer.com/dims/large/And%20Beyond_RCM_1250-Edit_Stephen%20Giorgi.jpg);
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	display: flex;
	align-items: center;
	justify-content: center;
}
.lost-header {
	font-size: 100px;
	margin: 0;
	font-family:"lemonchickenregular";
	font-weight: bold;
	font-size: 48px;
	-webkit-animation: lost 5s;
	        animation: lost 5s;
	-webkit-animation-iteration-count: infinite;
	        animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
}

@-webkit-keyframes lost {
	0% {
		-webkit-transform:none;
		        transform:none;
	}

	100% {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

@keyframes lost {
	0% {
		-webkit-transform:none;
		        transform:none;
	}

	100% {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}
/**
 * New Zealand Styles
 */
.new-zealand .park-header .park-name {
	width:500px;
}
.new-zealand .park-header .other-links {
	width:600px;
}
.new-zealand .park-header .other-links p { margin: 0; }
.new-zealand .visit-details { width: 100%; }

.new-zealand .react-tabs__tab {
	border-top:5px solid transparent;
}

.new-zealand .trip-info {
	display: grid;
	grid-template-columns: 3fr 1fr;
	grid-template-rows: 1 1fr;
	grid-column-gap: 40px;
	grid-row-gap: 5px;
}

@media only screen and (max-device-width: 667px) and (min-device-width: 375px) {
	.new-zealand .trip-info { display: flex; }
}
.new-zealand .review-day .day-activities { display: none; }

/**
 * Footer.css
 *
 * Styles for the footer
 */

#rainier-footer {
	min-height: 400px;
	width: calc(100% - 4px);
	border-top: 2px solid #000;
	background-image: url("//static.alphavega.com/images/svgs/black_noize.svg");
	background-repeat: repeat;
	background-color: #424242;
	padding: 2rem 2rem 1rem;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
}

#rainier-footer aside {
	width: 40vw;
	border-bottom: 1px solid;
	margin: 2rem;
	color:#fff;
}


#rainier-footer h4 {
	font-family: "lemonchickenregular";
	text-align: center;
	border-bottom: 1px solid;
	margin:0;
	padding: 0;
}

#rainier-footer ul {
	padding: 2rem 0 2rem 4rem;
	margin: 0;
}

#rainier-footer a {
	color: #5ee0ef;
	font-weight: normal;
	line-height: 1.4;
	border-bottom: 1px solid;
	-webkit-transition: 0.3s ease-out;
	transition: 0.3s ease-out;
}
#rainier-footer a:hover {
	color:#bc412b;
}

#rainier-footer i {
	margin-right: 1rem;
	width: 2rem;
}

.stats-section label {
	color: #2e3233;
	text-shadow: 0px 0 2px white;
	font-weight: bold;
}
.stats-section b {
	margin-left: 1rem;
}

.html5-info li::before {
	content: " ";
	display: inline-block;
	margin-right: 1rem;
	height: 20px;
	width: 20px;
	background-repeat: no-repeat;
	-webkit-transform:			translate(0,6px);
	        transform:			translate(0,6px);
	-webkit-transition: 		0.3s;
	transition: 		0.3s;
}

.html5-info .semantics::before {
	background-image: url("//static.alphavega.com/images/svgs/html5_semantics.svg");
}
.html5-info .css3::before {
	background-image: url("//static.alphavega.com/images/svgs/html5_css3_styling.svg");
}
.html5-info .ddd::before {
	background-image: url("//static.alphavega.com/images/svgs/html5_3d_effects.svg");
}
.html5-info .storage::before {
	background-image: url("//static.alphavega.com/images/svgs/html5_offline_storage.svg");
}
.html5-info li:hover::before {
	-webkit-transform: translate(30rem,6px);
	        transform: translate(30rem,6px);
}

/**
 * Media Queries
 */

@media screen and (max-width: 750px) {
	#rainier-footer aside {
		width:70vw;
		margin: 1rem 0;
	}

	#rainier-footer h4 {
		margin: 1rem;
	}
}

@media screen and (max-width: 400px) {
	#rainier-footer aside {
		width:95vw;
	}
}
/**
 * Make border-box the default CSS box-model
 */
html {
	font-size: 10px;
	font-family: 'Raleway', sans-serif;
	height: 100%;
}
*,
*::before,
*::after {
	box-sizing: border-box;
}

@font-face {
	font-family: 'lemonchickenregular';
	src: url('https://static.alphavega.com/fonts/LEMONCHI-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}


.nps-logo {
	width: 45px;
}

.App { font-size: 20px; }
.App .main-container {
	margin:20px;
	min-height: 100vh;
	max-width: 100vw;
	margin-top:40px;
}

.flex { display: flex; }
.hidden { display: none; }

@media screen and (max-width: 700px) {
	.App .main-container {
		margin:15px;
		margin-top: 40px;
	}
}
