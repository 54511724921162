.album-header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	border-bottom: 2px solid #173e8a;
	padding-bottom: 20px;
}

.album-info {
	width:45%;
	display: flex;
	flex-direction: column;
}




@media screen and (max-width: 400px) {
	.album-header {
		flex-direction: column;
	}

	.album-info {
		width: 100%;
	}
}