.three-60-photo-thumb {
	width:400px;	
	display: flex;
	flex-direction: column;
	align-items: center;
}

.three-60-photo-thumb .title {
	text-align: center;
	cursor: pointer;	
}